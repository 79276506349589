import React from "react";
import { Icon, Icons } from "./Icon/Icon";
import { FILES_TYPE_ICONS } from '../constants/attachments';
import { humanFileSize } from '../helpers/humanFileSize';

export interface FileSizeByType {
    [key: string]: number;
}

interface AttachmentProps {
    className?: string;
    // isProcessing?: boolean;
    file: File | null;
    removeFile: (e: any) => Promise<void>
}

export const Attachment = (
        {
            className,
            // isProcessing = false,
            file = null,
            removeFile
        }: AttachmentProps,
    ) => {

        const getFileExtension = () => {
            if (file) {
                const extensionIdx = file.name.lastIndexOf(".");
                const extension = file.name.slice(extensionIdx + 1);
                return extension.toLowerCase();
            }
            return "";
        };

        return (
            <div className={`w-full max-w-200 flex p-4 border border-gray-blue rounded-md cursor-pointer ${className}`}>
                <div className="flex items-center mr-auto">
                    <Icon
                        className="mr-5"
                        icon={FILES_TYPE_ICONS[getFileExtension()] || Icons.File}
                        width="32px"
                        height="32px"
                    />
                    <div>
                        <div className="pb-2 max-w-[550px] flex items-center gap-1">
                            {/* {isProcessing && <Icon
                                className="fill-primary"
                                icon={Icons.Loader}
                                width="16px"
                                height="16px"
                            />} */}
                            <span className="block truncate w-full">{file?.name}</span>
                        </div>
                        {/* {!isProcessing && (
                            <div className="text-gray-text">{humanFileSize(file?.size, true)}</div>
                        )} */}
                        <div className="text-gray-text">{humanFileSize(file?.size, true)}</div>
                    </div>
                </div>
                <div>
                    <div onClick={(e) => removeFile(e)} className=" px-1">
                        <Icon
                            icon={Icons.Delete}
                            width={20}
                            height={20}
                            color="fill-blue-drk cursor-pointer"
                        />
                    </div>
                </div>
            </div>
        );
};
