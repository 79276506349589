import React, { ReactNode } from "react";
import { DialogTitle } from "@headlessui/react";
import { Icon, Icons } from "../Icon/Icon";
import { ModalBody as Body } from "./ModalBody";
import { ModalFooter as Footer } from "./ModalFooter";
import { BaseModal } from "./BaseModal";

export const sizes = {
    small: "max-w-100",
    medium: "max-w-125",
    extraMedium: "max-w-142 2xl:max-w-[710px]",
    large: "max-w-150",
    huge: "max-w-200",
};

export type TModalSyzes = keyof typeof sizes;

interface ModalProps {
    isOpen?: boolean;
    onClose?: () => void;
    setIsOpen?: (open: boolean) => void;
    title: string;
    description?: string;
    children: ReactNode;
    className?: string;
    id?: string;
    size?: TModalSyzes;
    hideModalHeader?: boolean;
}

export const Modal = ({
    size = "extraMedium",
    isOpen = false,
    setIsOpen,
    onClose,
    title = "",
    description = "",
    children,
    className,
    id,
    hideModalHeader = false,
}: ModalProps) => {
    const handleClose = () => {
        setIsOpen?.(false);
        onClose?.();
    };

    return (
        <BaseModal isOpen={isOpen} size={size} className={className} id={id}>
            {!hideModalHeader && (
                <div className={"w-full flex items-start px-6 2xl:px-7.5 mt-6.5 2xl:mt-8"}>
                    <div>
                        <DialogTitle
                            as="h3"
                            className="text-lg font-bold text-gray-900 2xl:!text-4xl 2xl:!leading-[41px]"
                            id="modalDialogTitle"
                        >
                            {title}
                        </DialogTitle>
                        {description && (
                            <p className="text-gray-text pt-1 break-anywhere">
                                {description}
                            </p>
                        )}
                    </div>
                    <div onClick={handleClose} className="cursor-pointer ml-auto">
                        <Icon
                            icon={Icons.Close}
                            width="32px"
                            height="32px"
                            className={"content-end 2xl:!w-10 2xl:!h-10"}
                            color="fill-blue-drk"
                            id="modalDialogClose"
                        />
                    </div>
                </div>
            )}

            {children}
        </BaseModal>
    );
};

Modal.Body = Body;
Modal.Footer = Footer;
