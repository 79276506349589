import React from 'react';
import { NotesLogging } from './pages/NotesLogging';
import { CustomToastContainer } from './Uikit/CustomToastContainer/CustomToastContainer';

const App = () => {
    
    return (
        <div className="h-screen">
            <NotesLogging />
            <CustomToastContainer autoClose={5000} hideProgressBar={true} />
        </div>
    );
};

export default App;
