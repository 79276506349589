import React from "react";
import { Loader } from './Loader/Loader';
import { ProgressMessage, ProgressMessageTranslate } from "../types/ProgressMessage";

export interface FileSizeByType {
    [key: string]: number;
}

interface ProcessingLoaderProps {
    className?: string;
    title?: string;
    subscription?: string;
    progressMessage?: ProgressMessage;
}

export const ProcessingLoader = (
        {
            className = '',
            title = 'Идет распознавание записи',
            subscription = 'Пожалуйста, не закрывайте это окно ',
            progressMessage,
        }: ProcessingLoaderProps,
    ) => {
        return (
            <div className={`w-full max-w-200 flex flex-col items-center gap-1 p-8 border border-gray-blue rounded-lg ${className}`}>
                <Loader />
                <p className="mt-3 text-xl font-semibold">{title}</p>
                {progressMessage && (
                    <p>Текущий прогресс: {ProgressMessageTranslate[progressMessage as ProgressMessage]}</p>
                )}
                <p className="text-blue-dark">
                    {
                        progressMessage !== ProgressMessage.ERROR ? subscription : 'Обновите страницу и попробуйте еще раз'
                    }
                </p>
            </div>
        );
};
