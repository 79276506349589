import { Document, Paragraph, TextRun } from 'docx';

const re = /(SPEAKER_)\w+/g;

export const getDocFile = (rawText: string, handleSpeakers: boolean) => {
    // handleSpeakers - флаг выделения спикеров
    const paragraphsChildrens: TextRun[][] = [];

    rawText.split('\n')
        .filter((item: string) => item)
        .forEach((item: string, index: number) => {
            const breakString = (handleSpeakers && index !== 0 && item.includes('SPEAKER')) ? 1 : 0;
            const textRun = new TextRun({
                text: ` ${item}`,
                break: breakString,
            });
            let children = [ textRun ];

            if (handleSpeakers) {
                const speakerMatched = item.match(re);

                if (speakerMatched) {
                    children = [
                        new TextRun({
                            text: '',
                            break: breakString,
                        }),
                        new TextRun({
                            text: speakerMatched[0],
                            bold: speakerMatched ? true : false
                        }),
                        new TextRun({
                            text: item.replace(re, ''),
                        }),
                    ]
                } else {
                    paragraphsChildrens.at(-1)?.push(textRun);
                    return;
                }
            }

            paragraphsChildrens.push(children)
        });

    const paragraphs: Paragraph[] = paragraphsChildrens.map((item) => {
        return new Paragraph({
            children: item
        });
    });

    return new Document({
        // styles: {
        //     default: {
        //         document: {
        //             run: {
        //                 size: "16pt",
        //             },
        //         },
        //     },
        // },
        sections: [
            {
                properties: {},
                children: paragraphs
            }
        ]
    });
}