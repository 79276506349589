import React, { ReactNode } from "react";
import clsx from "clsx";

import { Tab as HeadlessUiTab } from "@headlessui/react";
import { Body } from "./Body";

interface ContentProps {
    children: ReactNode;
    className?: string;
    panelClassName?: string;
}

export const Content = ({ children, className, panelClassName }: ContentProps) => {
    return (
        <div className={clsx("w-full h-full", className)}>
            <HeadlessUiTab.Panels className={clsx("w-full h-full", panelClassName)}>{children}</HeadlessUiTab.Panels>
        </div>
    );
};

Content.Body = Body;
