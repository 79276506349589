import React, { ReactNode } from "react";
import { Dialog, DialogPanel } from "@headlessui/react";
import clsx from "clsx";
import { AnimatePresence, motion } from 'framer-motion';

import { TModalSyzes, sizes } from "./Modal";

interface BaseModalProps {
    children: ReactNode;
    isOpen: boolean;
    size?: TModalSyzes;
    className?: string;
    id?: string;
}

export const BaseModal = ({ isOpen, children, size = "extraMedium", className, id }: BaseModalProps) => {
    return (
      <AnimatePresence>
            <Dialog as="div" open={isOpen} className="fixed z-50" onClose={() => {}} static >
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="fixed inset-0 bg-black/30"
            />
                <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
                    <DialogPanel className=""
                        as={motion.div}
                        initial={{ opacity: 0, scale: 0.95 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.95 }}
                    >
                        <div
                            className={clsx(
                                sizes[size],
                                "border-[1px] border-[#EAEDF3] inline-block w-full",
                                "text-left align-middle transition-all",
                                "transform bg-white shadow-xl rounded-lg 2xl:rounded-2lg",
                                className,
                            )}
                            id={id}
                        >
                            {children}
                        </div>
                    </DialogPanel>
                </div>
            </Dialog>
        </AnimatePresence>
    );
};
