import { Client } from "@stomp/stompjs";
import { WssMessage } from "../../types/WssMessage";

let stompClient: any = null;
let subscribers: any[] = [];
let subscribtions: any[] = [];

const NotificationConnect = () => {
    const activate = () => {
        stompClient.activate();
    };

    if (stompClient) {
        return { stompClient };
    }

    stompClient = new Client({
        brokerURL: "wss://qm-demo.gran-soft.ru/api/websocket/notifications",
    });

    stompClient.onConnect = () => {
        ["session", "progress"].forEach((item) => {
            const subscription = stompClient.subscribe(`/user/topic/${item}`, (message: any) => {
                subscribers.forEach((callback) => callback(message));
            });
            subscribtions.push(subscription);
        });
    };

    stompClient.onDisconnect = () => {
        subscribtions.forEach((subscribtion) => {
            subscribtion.unsubscribe();
        });
        subscribtions = [];
        subscribers = [];
    };

    stompClient.activate();

    return { activate };
};

export const NotificationSubscribe = (onMessage: (message: WssMessage) => Promise<void>) => {
    subscribers.push((message: any) => {
        let parsedMessage;

        if (message.isBinaryBody) {
            const stringFromBinary = new TextDecoder().decode(message._binaryBody);
            parsedMessage = JSON.parse(stringFromBinary);
        } else {
            parsedMessage = JSON.parse(message._body);
        }

        onMessage(parsedMessage).then();
    });
};

export default NotificationConnect;
