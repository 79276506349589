import React, { ReactNode } from "react";
import { Tab as HeadlessUiTab } from "@headlessui/react";
import clsx from "clsx";

import { Tooltip } from "../../Uikit/Tooltip";

interface TabProps {
    title: string;
    disabled?: boolean;
    tooltip?: string;
    className?: string;
    error?: boolean;
    onClick?: () => void;
    additionalElement?: ReactNode;
    selectedClassName?: string;
}

export const Tab = ({
    title,
    tooltip,
    disabled,
    error,
    className,
    onClick,
    additionalElement,
    selectedClassName,
}: TabProps) => {
    const body = (
        <HeadlessUiTab
            disabled={disabled}
            className={({ selected }) =>
                clsx(
                    "w-fit pt-1.5 pb-4 p2 focus:outline-none 2xl:text-md nowrap",
                    error && "!text-danger !border-danger",
                    disabled && "text-gray-light hover:text-gray-light",
                    className,
                    selected
                        ? `border-b-[3px] border-[#1380CE] text-[#262626] z-10 ${selectedClassName}`
                        : "border-gray-blue text-[#939292] hover:text-[#1380CE]",
                )
            }
            onClick={() => {
                onClick?.();
            }}
        >
            <>
                {additionalElement}
                {title}
            </>
        </HeadlessUiTab>
    );

    return (
        <>
            {tooltip && (
                <Tooltip placement="top" arrow={true} content={tooltip}>
                    {body}
                </Tooltip>
            )}
            {!tooltip && body}
        </>
    );
};
