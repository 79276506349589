import React, { ReactNode, useEffect, useState } from "react";
import { Tab as HeadlessUiTab } from "@headlessui/react";
import clsx from "clsx";

import { Icon, Icons } from "../../Uikit/Icon/Icon";
import { Tab } from "./Tab";

interface TabsProps {
    className?: string;
    label?: string | ReactNode;
    children: ReactNode;
    id?: string;
    description?: string;
    childrenWrapperClassName?: string;
}
export const Tabs = ({ className, label, children, id, description, childrenWrapperClassName }: TabsProps) => {
    const [, setTrick] = useState(false);

    const isShowLeftArrow = () => {
        const tabs = document.getElementById("tabs");
        return !(!tabs || tabs.scrollLeft === 0);
    };
    const onLeftArrowClick = () => {
        const tabs = document.getElementById("tabs");

        if (!tabs) {
            return;
        }

        const buttons = tabs.querySelectorAll("button");
        let position = 0;

        for (let i = 0; i < buttons.length; i++) {
            if (buttons[i].offsetLeft >= tabs.scrollLeft) {
                position = buttons[i - 1].offsetLeft - buttons[i - 1].offsetWidth / 2;
                break;
            }
        }

        tabs.scrollTo({ left: position });
        setTrick((prev) => !prev);
    };

    const isShowRightArrow = () => {
        const tabs = document.getElementById("tabs");
        return !(
            !tabs ||
            tabs.scrollWidth === tabs.offsetWidth ||
            tabs.scrollLeft === tabs.scrollWidth - tabs.offsetWidth
        );
    };
    const onRightArrowClick = () => {
        const tabs = document.getElementById("tabs");

        if (!tabs) {
            return;
        }

        const buttons = tabs.querySelectorAll("button");
        let position = 0;

        for (let i = 0; i < buttons.length; i++) {
            if (buttons[i].offsetLeft > tabs.offsetWidth + tabs.scrollLeft) {
                position = buttons[i].offsetLeft;
                break;
            }
        }

        if (position === 0) {
            position = tabs.scrollWidth;
        }

        tabs.scrollTo({ left: position - tabs.offsetWidth });
        setTrick((prev) => !prev);
    };

    useEffect(() => {
        setTrick((prev) => !prev);
    }, [children]);

    return (
        <HeadlessUiTab.List
            id={id}
            className={clsx("relative w-full border-gray-blue z-0 pb-2", className ?? "flex justify-between")}
        >
            {isShowLeftArrow() && (
                <div
                    className="hidden sm:flex justify-center items-center absolute top-1 left-0 bg-blue-gray rounded-full w-6 h-6 z-20 cursor-pointer"
                    onClick={onLeftArrowClick}
                >
                    <Icon icon={Icons.ChevronLeft} width={16} height={16} color="fill-blue-drk" />
                </div>
            )}
            <div id="tabs" className={`flex overflow-auto sm:overflow-hidden space-x-8 ${childrenWrapperClassName}`}>
                {children}
            </div>
            {isShowRightArrow() && (
                <div
                    className="hidden sm:flex justify-center items-center absolute top-1 right-0 bg-blue-gray rounded-full w-6 h-6 z-20 cursor-pointer"
                    onClick={onRightArrowClick}
                >
                    <Icon icon={Icons.ChevronRight} width={16} height={16} color="fill-blue-drk" />
                </div>
            )}
            {description && <div className="p2 text-gray pb-4">{description}</div>}
            {label && <div className="p2 text-gray pb-4">{label}</div>}
        </HeadlessUiTab.List>
    );
};

Tabs.Tab = Tab;
